import { CustomLanguageService } from '../core/services/language-service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Directive, Inject, OnDestroy, Optional, inject } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { LangConfig } from '@app/core/configs/lang.config';

@Directive()
export abstract class BaseComponent implements OnDestroy {
  destroy$: Subject<boolean> = new Subject();
  showLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  showFilesLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  translation = inject(TranslateService);
  languageService: CustomLanguageService = inject(CustomLanguageService);
  currentLanguage!: any;
  breadcrumbItems: MenuItem[] = [];
  protected translationsList: any = {};

  constructor(
    @Optional() @Inject(String) protected translationArr: string[] = [],
  ) {
    this.initializeTranslations();
    this.translatedContent();
  }

  OnInit(): void {}

  selectLanguage(language: LangConfig): void {
    this.updateQueryStringParameter(location.href, 'lang', language);
    this.languageService.changeLanguage(language);
    this.translation.use(language);
    this.currentLanguage = language;
  }

  updateQueryStringParameter(url: string, key: string, value: string) {
    var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
    var separator = url.indexOf('?') !== -1 ? '&' : '?';
    if (url.match(re)) {
      return url.replace(re, '$1' + key + '=' + value + '$2');
    } else {
      return url + separator + key + '=' + value;
    }
  }

  // hooks
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private initializeTranslations(): void {
    this.currentLanguage = this.languageService.currentLanguage$.value;
    if (this.translationArr && this.translationArr.length > 0) {
      this.translation
        .get([...this.translationArr])
        .pipe(takeUntil(this.destroy$))
        .subscribe((translations: any) => {
          this.translationsList = translations;
        });
    }
    this.translation.onLangChange
      .pipe(takeUntil(this.destroy$))
      .subscribe((lang: LangChangeEvent) => {
        this.currentLanguage = lang.lang as LangConfig;
        if (this.translationArr && this.translationArr.length > 0) {
          this.translation
            .get([...this.translationArr])
            .pipe(takeUntil(this.destroy$))
            .subscribe((translations: any) => {
              this.translationsList = translations;
              this.translatedContent();
            });
        }
      });
  }

  protected abstract translatedContent(): void;
}
